function getDate(date) {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
}
function getprice(product) {
  
  let currency = localStorage.getItem("currency");
  if (product.price) {
   
    return currency
      ? currency == "dollar"
        ? `${product.price} $`
        : `${product.dinar_price} د.ل`
      : `${product.price} $`;
  } else {
    return "";
  }
}

function isNotLogin() {
  let token = localStorage.getItem('token');
  if (token) {
    return false
  }else{
    return true
  }
}
function LogOut() {
  let token = localStorage.removeItem('token');
  
  window.location.href = '/';
}
function getCurrency() {
  return (localStorage.getItem("currency")) ?  localStorage.getItem("currency") : 'dinar' ;
}
function changeCurrency() {
  if (localStorage.getItem("currency") && localStorage.getItem("currency") == 'dinar' ) {  localStorage.setItem("currency",'dollar') } else {
    localStorage.setItem("currency",'dinar')
  } 
  window.location.href = '/';
}
export { getDate, getprice , isNotLogin , LogOut, getCurrency , changeCurrency};
