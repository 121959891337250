import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { instance } from '../../modules/instance';
import Spinner from "./spinner";

export class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            isLoading: false,
        };
        this.handleSearchInput = this.keyUpHandler.bind(this);
        this.handleClick = this.openSearch.bind(this);
    }
    openSearch() {
        this.setState({ products: [], isLoading: false });
        const search = document.querySelector("#custom-search");
        const input = document.querySelector("#search-input");
        document.getElementById('search-input').value = "";
        search.classList.toggle("active");//toggle metode add and remove in the same time class
        input.focus();//i put focus in my input

    }


    keyUpHandler(e) {
        let query = e.target.value;
        this.setState({ products: [], isLoading: true });
        let _this = this;
        instance.get(`/products/search?q=${query}`).then((res) => {
            _this.setState({ products: res.data })
        }).then(() => {
            this.setState({ isLoading: false });
        })
    }
    render() {
        return (
            <ul class="list-group non-dots">
                <li class="">

                    <div className="search " id="custom-search" dir="rtl">
                        <input type="text" onKeyUp={this.handleSearchInput} className="input" id="search-input" placeholder="البحث عن ..." />
                        <button onClick={this.handleClick} className="btn-2 btn-link btn-sm text-primary p-0" id="search-btn">
                            {
                                (this.state.isLoading) ? (
                                    <Spinner />
                                ) : (
                                    <i className="fa fa-search" style={{ color: 'rgb(204, 153, 51)' }} aria-hidden="true"></i>
                                )
                            }

                        </button>
                    </div>
                </li>
                <li class="search-results">
                    {this.state.products.map((product, index) => (
                        (
                            <li onClick={this.handleClick} class="list-group-item py-0 " key={index}>
                                <Link  to={`/products/${product.id}/show`}>
                                    <div className="row text-dark">
                                        <div className="col-lg-3">
                                            <img src={product.img} width="30" height="30" />
                                        </div>
                                        <div className="col-lg-9">
                                            <small>{product.title}</small>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    ))}
                </li>


            </ul>
        )
    }
}

export default SearchBar
