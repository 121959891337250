import React from "react";
import { Link } from "react-router-dom";
import { getDate, getprice } from "../../modules/functions";

export default function Product(props) {
  
  return (
    <div className='col-md-6 col-lg-3 d-flex align-items-stretch aos-init aos-animate'>
      <div className='listing-wrap listing-wrap-products'>
        <Link to={`/products/${props.id}/show`}>
          <a
            className='img img-property d-flex align-items-center justify-content-center glightbox bg-white'
            style={{
              backgroundImage: `url(${props.src})`,
            }}>
            <div className='icon d-flex align-items-center justify-content-center'>
              <span className='fa fa-search'></span>
            </div>
          </a>
        </Link>
        <div className='text text-center'>
          <div className='icon d-flex align-items-center justify-content-center bg-white'>
            <img src={props.icon} width='30' />
          </div>
          <span className='subheading'>{props.category}</span>
          <h3 className='mb-0 pb-0'>
            <Link to={`/products/${props.id}/show`}>
              <a>{props.title}</a>
            </Link>
          </h3>
          <span className='subheading pt-0 mt-0'>{props.code}</span><br />
          <span className='subheading pt-0 mt-0'>{getprice(props.product)}</span>
          <div className='info-wrap2 d-flex align-items-center text-center'>
            <p className='meta'>
              <a>
                <i className='fa fa-eye mx-2'></i>
                {props.views}{" "}
              </a>
              <a>
                <i className='fa fa-calendar mx-1 mr-3'></i>
                <span>{props.date ? getDate(props.date) : ""}</span>{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
