import React, { Component } from "react";
import { Link } from "react-router-dom";
import { instance } from "../../modules/instance";
import LoadingPage from "./loadingpage";

export class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    this.getCategories();
  }
  getCategories() {
    this.setState({ isLoading: true });
    instance
      .get("/categories")
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <LoadingPage />
        ) : (
         
          <section className='ftco-section ftco-no-pt ftco-no-pb bg-light'>
            <div className='container'>
              <div className='row g-0 '>
                <div className='col-md-12 services-wrap'>
                  <div className='row g-3 justify-content-center'>
                    {this.state.categories.map((category) => (
                      // <div className='col-md-4 col-lg-2 col-6 text-center d-flex align-items-stretch' key={category.id}>
                      //   <a href={`/categories/${category.id}`} className='services'>
                      //     <div className='icon mb-1'>
                      //       <img src={category.img} width='80' />
                      //     </div>
                      //     <div className='text text-center'>
                      //       <h2>{category.name}</h2>
                      //     </div>
                      //   </a>
                      // </div>
                      <div className='col-md-6 col-lg-3 d-flex align-items-stretch aos-init aos-animate'  key={category.id}>
                      <div className='listing-wrap'>
                        <Link  to={`/categories/${category.id}`}>
                          <a
                            className='img img-property d-flex align-items-center justify-content-center glightbox bg-white'
                            style={{
                              backgroundImage: `url(${category.img})`,
                            }}>
                            <div className='icon d-flex align-items-center justify-content-center'>
                              <span className='fa fa-search'></span>
                            </div>
                          </a>
                        </Link>
                        <div className='text text-center'>
                          <h3 className='mb-0 pb-0'>
                            <Link to={`/categories/${category.id}`}>
                              <a>{category.name}</a>
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default Categories;
