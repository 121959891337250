import React from "react";
import { Link } from "react-router-dom";
import landScapeLogo from "../../assets/images/logo-landscape.svg";
import MenuIcon from "../../assets/images/menu-icon.svg";
import dinarPrice from "../../assets/images/dl.svg";
import dollarPrice from "../../assets/images/do.svg";
import { instance } from "../../modules/instance";
import {
  isNotLogin,
  LogOut,
  getCurrency,
  changeCurrency,
} from "../../modules/functions";
import SearchBar from "../public/searchbar";
instance.post("/set-visitor");

export default function Header() {
  return (
    <div>
      <div className='py-1 top-wrap bg-warning'>
        <div className='container-xl'>
          <div className='row justify-content-between'>
            <div className='col-12 topper d-flex mb-md-0 align-items-xl-center'>
              <div className='text'></div>
            </div>
          </div>
        </div>
      </div>
      <nav className='navbar navbar-expand-lg  ftco-navbar-light'>
        <div className='container-xl'>
          <Link className='navbar-brand d-flex ' to='/'>
            <img src={landScapeLogo} width='190' className='img-fluid' />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <img src={MenuIcon} width='40' className='img-fluid mr-3' />
          </button>
          
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav m-auto mb-0 pr-0 mr-0' dir='rtl'>
              <li className='nav-item  d-none d-sm-none d-md-none d-lg-block mt-2'  >
                <SearchBar />
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/'>
                  الرئيسية
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/about-us'>
                  عن الشركة
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/products'>
                  منتجاتنا
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/projects'>
                  المشاريع
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/show-rooms'>
                  صالة العرض
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/contact-us'>
                  تواصل معنا
                </Link>
              </li>
              {isNotLogin() ? (
                <li className='nav-item'>
                  <Link className='nav-link ' to='/login'>
                    تسجيل الدخول
                  </Link>
                </li>
              ) : (
                <li
                  className='nav-item'
                  onClick={() => {
                    LogOut();
                  }}>
                  <a href='#' className='nav-link '>
                    تسجيل الخروج
                  </a>
                </li>
              )}

              {(!isNotLogin()) ? (
                getCurrency() == "dollar" ? (
                  <li
                    onClick={() => {
                      changeCurrency();
                    }}
                    className='nav-item'>
                    <a className='nav-link ' href='#'>
                      <img src={dinarPrice} width='30' />
                    </a>
                  </li>
                ) : (
                  <li
                    onClick={() => {
                      changeCurrency();
                    }}
                    className='nav-item'>
                    <a className='nav-link ' href='#'>
                      <img src={dollarPrice} width='15' />
                    </a>
                  </li>
                )
              ) : (null)}

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
