import React, { Component } from 'react';
import bread from '../../assets/images/bread.jpg'
class Breadcrumb extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
        <section
        className='hero-wrap '
        style={{
          backgroundImage: `url(${(this.props.src ? this.props.src : bread)})`,
          height:'300px'
        }}>
        <div className='overlay'></div>
        <div className='container'>
          <div className='row no-gutters slider-text align-items-center justify-content-center'>
            <div className='col-lg-8 text-center pb-5'>
              <div className='row justify-content-center'>
                <div className='col-lg-9'>
                  <h1 className=''>{this.props.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Breadcrumb;
