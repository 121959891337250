import landScapeLogo from "../../assets/images/logo-landscape.svg";
import footerBackground from "../../assets/images/footer-bg.jpg";
import React, { Component } from "react";
import axios from "axios";

export class Footer extends Component {
  
  render() {
    return (
      <footer className='ftco-footer' style={{backgroundImage:`url("${footerBackground}")`}} dir='rtl'>
        <div className='container-xl'>
          <div className='row mb-5 pb-5 justify-content-between'>
            <div className='col-md-6 col-lg-3'>
              <div className='ftco-footer-widget mb-4'>
                <h2 className='ftco-heading-2 logo d-flex'>
                  <a
                    className='navbar-brand align-items-center'
                    href='index-2.html'>
                    <img
                      src={landScapeLogo}
                      className='img-fluid'
                      width='190'
                    />
                  </a>
                </h2>
                <p>
                  تقوم شركة اتحاد الرائدة للأثاث المكتبي في مجال صناعة الأثاث
                  باستيراد الأثاث من أكبر الشركات المتخصصة في صناعة الأثاث عالي
                  الجودة من جميع أنحاء العالم.
                </p>
                <ul className='ftco-footer-social list-unstyled mt-2'>
                  <li>
                    <a href='https://www.facebook.com/Etihad.Raeda.furniture/'>
                      <span className='fa fa-facebook'></span>
                    </a>
                  </li>
                  <li>
                    <a href='https://www.instagram.com/etihadraedaofficeproject/'>
                      <span className='fa fa-instagram'></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-md-6 col-lg-3'>
              <div className='ftco-footer-widget mb-0'>
                <h2 className='ftco-heading-2'>تواصل معنا</h2>
                <div className='block-23 mb-0'>
                  <ul className='pr-4'>
                    <li>
                      <span className='icon fa fa-map marker'></span>
                      <span className='text'>حي الاندلس - طرابلس - ليبيا</span>
                    </li>
                    <li>
                      <span className='icon fa fa-envelope marker'></span>
                      <span className='text'>
                        <a href='mailto:info@raeda.ly'>info@raeda.ly</a>
                      </span>
                    </li>
                    <li>
                      <span className='icon fa fa-envelope marker'></span>
                      <span className='text'>
                        <a href='mailto:sales@raeda.ly'>sales@raeda.ly</a>
                      </span>
                    </li>
                    <li>
                      <span className='icon fa fa-phone marker'></span>
                      <span className='text'>
                        <a href='tel:+218915600560' dir='ltr'>
                          +218 91 560 05 60
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className='icon fa fa-phone marker'></span>
                      <span className='text'>
                        <a href='tel:+218922170288' dir='ltr'>
                          +218 92 217 02 88
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className='icon fa fa-phone marker'></span>
                      <span className='text'>
                        <a href='tel:+218217229641' dir='ltr'>
                          +218 21 722 96 41
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='ftco-footer-widget mb-0'>
                {/* https://www.instagram.com/etihadraedaofficeproject/ */}
                <div className='block-23 mb-0'>
				<div className="fb-page" data-href="https://www.facebook.com/Etihad.Raeda.furniture" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Etihad.Raeda.furniture" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Etihad.Raeda.furniture">‏شركة الاتحاد الرائدة للأثاث المكتبي‏</a></blockquote></div>
				</div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid px-0 py-4 bg-darken '>
          <div className='container-xl'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <small>جميع الحقوق محفوظة لشركة اتحاد الرائدة © 2021</small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

/*
<div className="col-md-6 col-lg-2">
					<div className="ftco-footer-widget mb-4">
						<h2 className="ftco-heading-2">روابط سريعة</h2>
						<ul className="list-unstyled pr-4">
                            <li ><Link to="/"><span className="fa fa-chevron-left ml-2"></span> الرئيسية</Link></li>
                            <li ><Link to="/about-us"><span className="fa fa-chevron-left ml-2"></span> عن الشركة</Link></li>
                            <li ><Link to="/products"><span className="fa fa-chevron-left ml-2"></span> منتجاتنا</Link></li>
                            <li ><Link to="/projects"><span className="fa fa-chevron-left ml-2"></span> المشاريع</Link></li>
                            <li ><Link to="/contact-us"><span className="fa fa-chevron-left ml-2"></span> تواصل معنا</Link></li>
						</ul>
					</div>
				</div>
*/
