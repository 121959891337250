import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import about from "../assets/images/about.jpg";
import about1 from "../assets/images/about-1.png";
import about2 from "../assets/images/about-2.png";
class About extends Component {
  render() {
    return (
      <>
        <Breadcrumb title='عن الشركة' />

        <section className='ftco-section ftco-about-section ftco-no-pt ftco-no-pb  bg-white'>
          <div className='container-xl'>
            <div className='row g-xl-5'>
              <div className='col-md-7 py-5 heading-section text-right'>
                <div className='py-md-5'>
                 
                  <h2 className='mb-4 text-dark'>
                  عن إتحاد الرائدة للاثاث المكتبي 
                  </h2>
                  <p dir="rtl">
                    قدرة شركة الاتحاد الرائدة من أبرز موردي الأثاث عالي الجودة
                    في ليبيا. نحن واحدة من أقدم الشركات في صناعة الأثاث، و نقدم
                    في ذلك افضل التصاميم و العروض المبتكرة و الصديقة للبيئة منذ
                    نشأتها في عام 1964. بدأت شركة الاتحاد الرائدة للأثاث في
                    مدينة طرابلس - شارع عمر المختار ومنذ البداية، ركزت الشركة
                    على مبادئها الأساسية في خدمة العملاء و اعتماد الجودة والقيمة
                    والابتكار، وهذه المبادئ مكنت الشركة من كسب ثقة العملاء.
                  </p>
                </div>
              </div>
              <div className='col-md-5 d-flex align-items-stretch'>
                <div
                  className='img w-100'
                  style={{ backgroundImage: `url(${about})` }}></div>
              </div>
              <div className="col-12 mt-5" dir="rtl">
                  <div className="row mb-5 pb-5 justify-content-center text-center text-dark">
                      <div className="col-md-6 px-2">
                          <img className="img-fluid my-4" src={about1} width="400"/>
                          
                          <h3 className="text-dark"> <strong>الجودة</strong></h3>
                          <p className="text-dark">
                          وعلى مدى أكثر من 55 عاما، كانت شركة الاتحاد الرائدة للأثاث علي قدر عالي في توفير مرونة وديناميكية متسقة مع التغيرات العالمية والتكنولوجية. الشركة تقدم باستمرار الأثاث وفقا للتكنولوجيا الجديدة والعالية للبقاء في مقدمة صناعة الأثاث
                          </p>
                      </div>
                      <div className="col-md-6 px-2">
                          <img className="img-fluid my-4" src={about2} width="400"/>
                          <h3 className="text-dark"> <strong>الموردين</strong></h3>
                          <p className="text-dark">
                          في مجال صناعة الأثاث، فإننا نقوم باستيراد الأثاث من أكبر الشركات المتخصصة في صناعة الأثاث عالي الجودة من جميع أنحاء العالم. و يستمر موردينا في الاستثمار في التكنولوجيا والآلات الجديدة لمواصلة وتيرة الإنتاج مع تصاميم وأساليب جديدة لتناسب احتياجات العملاء.
                          </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default About;
