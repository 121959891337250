import React, { Component } from 'react'
import InfiniteCarousel from 'react-leaf-carousel';
import { instance } from '../../modules/instance';
export class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            isLoading: true,
        };
    }
    getClients() {
        instance
            .get("/clients")
            .then((res) => {

                this.setState({ clients: res.data });
            })
            .catch((err) => {
                alert(err);
                window.location.href = "/";
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }
    componentDidMount() {
        this.getClients();
    }
    render() {
        return (
            <div className="container mb-5">
                <h4 className="text-primary text-center mb-4">عملائنا المميزون</h4>
               
                {!this.state.isLoading ? (
                    <InfiniteCarousel
                    breakpoints={[
                        {
                            breakpoint: 500,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            },
                          },
                          {
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            },
                          },
                    ]}
                    dots={false}
                    showSides={false}
                    incrementalSides={true}
                    sidesOpacity={0.5}
                    sideSize={0.1}
                    slidesToScroll={2}
                    slidesToShow={3}
                    autoCycle={true}
                    scrollOnDevice={true}
                >
                    {this.state.clients.map(client => {
                        return (
                            <div className="text-center">
                                <img className="client-logo" key={client.id} src={client.img} />
                            </div>
                        );
                    })}
                </InfiniteCarousel>
                ) : null}

                
            </div>
        )
    }
}

export default Clients
