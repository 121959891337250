import React, { Component } from "react";
import loadingLogo from "../../assets/images/loading-logo.svg";
export class LoadingPage extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
        <div id='wrapper'>
          <img id='loading-logo-img' src={loadingLogo} />
        </div>
      );
  }
}

export default LoadingPage;
