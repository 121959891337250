import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import contactus from "../assets/images/login.jpg";
import { instance } from "../modules/instance";
import CustomAlert from "../components/public/customalert";
class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: false,
      resMessage: [],
    };
  }
  makeAuth() {
    this.setState({ isLoading: true });
    let data = {
      email: this.state.email,
      password: this.state.password,
    };
    instance
      .post("/authenticate", data)
      .then((res) => {
        if (res.data.error) {
          this.setState({ resMessage: null });
          this.setState({
            resMessage: (
              <CustomAlert type='danger' message={res.data.errors[0]} />
            ),
          });
        } else {
          this.setState({ resMessage: null });
          this.setState({
            resMessage: (
              <CustomAlert type='success' message='تم تسجيل دخولك بنجاح' />
            ),
          });
          localStorage.setItem("token", res.data.token);
          setTimeout(function () {
            window.location.href = "/";
          }, 400);
        }
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    return (
      <>
        <Breadcrumb title='تسجيل الدخول' />
        <section className='ftco-section ftco-no-pt ftco-no-pb'>
          <div className='container text-right'>
            <div className='row no-gutters justify-content-center'>
              <div className='col-md-12'>
                <div className='wrapper'>
                  <div className='row g-0'>
                    <div className='col-lg-6' dir='rtl'>
                      <div className='contact-wrap w-100 p-md-5 p-4'>
                        <form
                          id='contactForm'
                          name='contactForm'
                          className='contactForm'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <input
                                  type='email'
                                  className='form-control'
                                  name='email'
                                  id='email'
                                  onChange={(event) => {
                                    this.setState({
                                      email: event.target.value,
                                    });
                                  }}
                                  value={this.state.email}
                                  placeholder='البريد الإلكتروني'
                                />
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <input
                                  type='password'
                                  className='form-control'
                                  name='password'
                                  id='password'
                                  onChange={(event) => {
                                    this.setState({
                                      password: event.target.value,
                                    });
                                  }}
                                  value={this.state.password}
                                  placeholder='كلمة المرور'
                                />
                              </div>
                            </div>

                            <div className='col-md-12'>
                              <div className='form-group'>
                                <button
                                  type='button'
                                  className='btn btn-primary mt-3 my-2'
                                  disabled={
                                    this.state.email === "" ||
                                    this.state.password === ""
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    this.makeAuth();
                                  }}
                                  >
                                  تسجيل الدخول
                                </button>
                              </div>
                              <div className='form-group'>
                                {this.state.isLoading ? (
                                  <>
                                    <div
                                      className='spinner-grow text-warning my-2'
                                      role='status'>
                                      <span className='sr-only'>
                                        Loading...
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {this.state.resMessage}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className='col-lg-6 d-flex align-items-stretch'>
                      <div
                        className='img w-100'
                        style={{
                          backgroundImage: `url(${contactus})`,
                          height: "400px",
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LoginPage;
