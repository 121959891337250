import axios from "axios";

let token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
let data = {};
if (token !== null) {
  data = {
    token,
  };
}

export const instance = axios.create({
  baseURL: "https://sys.raeda.ly/api",
  responseType: "json",
  params: data,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});
