import React from "react";
import '../../assets/css/alert.css'
const CustomAlert = (props) => {
  return (
    <small className={`alert alert-${props.type} d-block p-3`} role='alert'>
      {props.message}
    </small>
  );
};

export default CustomAlert;
