import React from "react";

/* styles */
import "./assets/css/animate.css";
import "./assets/css/flaticon.css";
import "./assets/css/tiny-slider.css";
import "./assets/css/glightbox.min.css";
import "./assets/css/aos.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";

/* styles */

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/fixed/Footer";
import Header from "./components/fixed/Header";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import ScrollTop from "./components/public/ScrollTop";
import Projects from "./pages/projects";
import ShowRooms from "./pages/show-rooms";
import SingleProject from "./pages/single-project";
import history from "./modules/history";
import Products from "./pages/products";
import SingleProduct from "./pages/single-product";
import Category from "./pages/category";
import LoginPage from "./pages/login";
import { isNotLogin } from "./modules/functions";



export default function App() {
  return (
    <Router history={history}>
      <ScrollTop />
      <div>
        <Header />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>

          <Route path='/about-us'>
            <About />
          </Route>
          <Route path='/contact-us'>
            <Contact />
          </Route>
         
          <Route
            path="/projects/:id/show"
            render={(props) => <SingleProject {...props} />}
          />
         
          <Route path='/projects'>
            <Projects />
          </Route>
          <Route path='/show-rooms'>
            <ShowRooms />
          </Route>
          <Route
            path="/products/:id/show"
            render={(props) => <SingleProduct {...props} />}
          />
          <Route
            path="/categories/:id"
            render={(props) => <Category {...props} />}
          />
          <Route path='/products'>
            <Products />
          </Route>
          {
            (isNotLogin()) ? (
              <Route path='/login'>
                <LoginPage />
              </Route>
            ) : (
              <></>
            )
          }

          <Route path='*'>
            <h3>not found</h3>
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
