import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import LoadingPage from "../components/public/loadingpage";
import { instance } from "../modules/instance";
import { getDate, getprice } from "../modules/functions";
import ProjectLandScape from "../components/public/project-landscape";
import Spinner from "../components/public/spinner";
export class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      related_products: [],
      isLoading: true,
      showType: "images",
      id: this.props.match.params.id,
    };
  }

  getProductData() {
    this.setState({ isLoading: true });
    instance
      .get(`/products/${this.props.match.params.id}/show`)
      .then((res) => {
        this.setState({
          product: res.data.product,
          related_products: res.data.related_products,
        });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/products";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getProductData();
    }
  }

  componentDidMount() {
    this.getProductData();
  }
  showDiv(type) {
    this.removeClass("images", "btn-dark");
    this.removeClass("video", "btn-dark");
    this.setState({ showType: type });
    this.removeClass(type, "text-dark");
    this.addClass(type, " btn-dark text-white");
    this.addClass(type == "images" ? "video" : "images", " text-dark");
    this.removeClass(type == "images" ? "video" : "images", "text-white");
  }
  addClass(id, className) {
    var v = document.getElementById(id);
    if(v){
      v.className += className;
    }
  }
  removeClass(id, className) {
    var element = document.getElementById(id);
    if(element){
      element.classList.remove(className);
    }
  }
  render() {
    return this.state.isLoading ? (
      <LoadingPage />
    ) : (
      <>
        <Breadcrumb src={this.state.product.img} />
        <section className='ftco-section'>
          <div className='container'>
            <div className='row' dir='rtl'>
              <div className='col-lg-8 blog-single'>
                <div className='text'>
                  <p className='meta'>
                    <a>
                      <i className='fa fa-eye mx-2'></i>
                      {this.state.product.views}{" "}
                    </a>
                    <a>
                      <i className='fa fa-calendar mx-1 mr-3'></i>
                      <span>
                        {this.state.product.created_at
                          ? getDate(this.state.product.created_at)
                          : ""}
                      </span>{" "}
                    </a>
                  </p>
                </div>
                <h1 className='mb-3'>{this.state.product.title}</h1>
                <h3 className='mb-3 text-bold'>
                  {this.state.product.price ? (
                    getprice(this.state.product)
                  ) : (
                    ""
                  )}
                </h3>
                <hr />
                <p className='sort-text'>{this.state.product.desc}</p>
                <div className='row'>
                  <div className='col-12 my-4'>
                    <ul class='nav nav-pills'>
                      {
                        (this.state.product.images.length > 0) ? (
                          <li class='nav-item mx-2'>
                            <button
                              class='btn nav-link text-dark'
                              id='images'
                              onClick={() => {
                                this.showDiv("images");
                              }}>
                              الصور
                            </button>
                          </li>
                        ) : (null)
                      }
                      {
                        (this.state.product.video) ? (
                          <li class='nav-item mx-2'>
                            <button
                              class='btn nav-link text-dark '
                              id='video'
                              onClick={() => {
                                this.showDiv("video");
                              }}>
                              فيديو
                            </button>
                          </li>
                        ) : (null)
                      }
                    </ul>
                  </div>
                </div>
                {this.state.showType == "images" ? (
                  <div className='row'>
                    <div className='col-12 my-3'>
                      <div
                        id='carouselExampleControls'
                        className='carousel slide'
                        data-ride='carousel'>
                        <div className='carousel-inner'>
                          {this.state.product.images.map((image, index) =>
                            index == 0 ? (
                              <div
                                key={image.id}
                                className='carousel-item active'>
                                <img
                                  className='d-block w-100'
                                  src={image.image}
                                  alt='First slide'
                                />
                              </div>
                            ) : (
                              <div key={image.id} className='carousel-item'>
                                <img
                                  className='d-block w-100'
                                  src={image.image}
                                  alt='First slide'
                                />
                              </div>
                            )
                          )}
                        </div>
                        <a
                          className='carousel-control-prev'
                          href='#carouselExampleControls'
                          role='button'
                          data-slide='prev'>
                          <span
                            className='carousel-control-prev-icon'
                            aria-hidden='true'></span>
                          <span className='sr-only'>Previous</span>
                        </a>
                        <a
                          className='carousel-control-next'
                          href='#carouselExampleControls'
                          role='button'
                          data-slide='next'>
                          <span
                            className='carousel-control-next-icon'
                            aria-hidden='true'></span>
                          <span className='sr-only'>Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-12 my-3'>
                      <video className='fullVideo' controls>
                        <source
                          src={
                            this.state.product.video
                              ? this.state.product.video.vid
                              : ""
                          }
                          type='video/mp4'
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}

                <div className='row mt-5'>
                  <div className='col-md-8 my-3'>
                    {
                      (this.state.product.sizes.length > 0) ? (<h4>الأحجام</h4>) : (null)
                    }
                    <div
                      id='carouselExampleIndicators'
                      class='carousel slide'
                      data-ride='carousel'>
                      <ol class='carousel-indicators'>
                        {this.state.product.sizes.map((image, index) =>
                          index == 0 ? (
                            <li
                              key={'image_' + image.id}
                              data-target='#carouselExampleIndicators'
                              data-slide-to={index}
                              class='active'></li>
                          ) : (
                            <li
                              key={'image_' + image.id}
                              data-target='#carouselExampleIndicators'
                              data-slide-to={index}></li>
                          )
                        )}
                      </ol>
                      <div class='carousel-inner'>
                        <div className='carousel-inner'>
                          {this.state.product.sizes.map((size, index) =>
                            index == 0 ? (
                              <div
                                key={`size_${size.id}`}
                                className='carousel-item active'>
                                <img
                                  className='d-block w-100'
                                  src={size.image}
                                  alt='First slide'
                                />
                                <div class="carousel-caption d-none d-md-block bg-dark p-0">
                                  <h5 className="text-white">{size.title}</h5>
                                  <p className="text-white">
                                    <small>W {size.W} | H {size.H} | L {size.L}</small>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div key={`size_${size.id}`} className='carousel-item'>
                                <img
                                  className='d-block w-100'
                                  src={size.image}
                                  alt='First slide'
                                />
                                <div class="carousel-caption d-none d-md-block">
                                  <h5 className="text-white">{size.title}</h5>
                                  <p className="text-white">
                                    <small>W {size.W} | H {size.H} | L {size.L}</small>
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <a
                        class='carousel-control-prev'
                        href='#carouselExampleIndicators'
                        role='button'
                        data-slide='prev'>
                        <span
                          class='carousel-control-prev-icon'
                          aria-hidden='true'></span>
                        <span class='sr-only'>Previous</span>
                      </a>
                      <a
                        class='carousel-control-next'
                        href='#carouselExampleIndicators'
                        role='button'
                        data-slide='next'>
                        <span
                          class='carousel-control-next-icon'
                          aria-hidden='true'></span>
                        <span class='sr-only'>Next</span>
                      </a>
                    </div>
                  </div>
                  <div className='col-md-4 my-3'>
                    {
                      (this.state.product.colors.length > 0) ? (<h4>الألوان</h4>) : (null)
                    }

                    <ul class='list-group' dir='ltr'>
                      {this.state.product.colors.map((color, index) => (
                        <li key={`color_${color.id}`} class='list-group-item d-flex justify-content-between align-items-center'>
                          <span>
                            <img src={color.image} width='70' />
                          </span>
                          <strong className='text-dark'>
                            <small>{color.name}</small>
                          </strong>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 sidebar pl-md-4'>
                <div className='sidebar-box'>
                  {
                    (this.state.related_products.length > 0) ? (<h3>منتجات ذات صله</h3>) : (null)
                  }
                  {this.state.related_products.map((product, index) =>
                    product.id !== this.state.product.id && index <= 4 ? (
                      <ProjectLandScape
                        title={product.title}
                        src={product.img}
                        id={product.id}
                        views={product.views}
                        date={product.created_at}
                        type='products'
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default SingleProduct;
