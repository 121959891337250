import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import LoadingPage from "../components/public/loadingpage";
import { instance } from "../modules/instance";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { getDate } from "../modules/functions";
import ProjectLandScape from "../components/public/project-landscape";
import Spinner from "../components/public/spinner";
export class SingleProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      project: [],
      projects: [],
      isLoading: true,
    };
  }
  getProjecData() {
    this.setState({ isLoading: true, project: [] });
    instance
      .get(`/projects/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ project: res.data });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/projects";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  getProjects() {
    this.setState({ isLoading: true, projects: [] });
    instance
      .get("/projects?take=4")
      .then((res) => {
        this.setState({ projects: res.data });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.getProjecData();
    this.getProjects();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getProjecData();
      this.getProjects();
    }
  }
  render() {
    return this.state.isLoading ? (
      <LoadingPage />
    ) : (
      <>
        <Breadcrumb src={this.state.project.img} />
        <section className='ftco-section'>
          <div className='container'>
            <div className='row' dir='rtl'>
              <div className='col-lg-8 blog-single'>
                <div className='text'>
                  <p className='meta'>
                    <a>
                      <i className='fa fa-eye mx-2'></i>
                      {this.state.project.views}{" "}
                    </a>
                    <a>
                      <i className='fa fa-calendar mx-1 mr-3'></i>
                      <span>
                        {this.state.project.created_at
                          ? getDate(this.state.project.created_at)
                          : ""}
                      </span>{" "}
                    </a>
                  </p>
                </div>
                <h2 className='mb-3'>{this.state.project.title}</h2>
                <p className='sort-text'>{this.state.project.desc}</p>
                <hr />

                <div className='row'>
                  <div className='col-12 my-3'>
                    <div
                      id='carouselExampleControls'
                      className='carousel slide'
                      data-ride='carousel'>
                      <div className='carousel-inner'>
                        {this.state.project.images.map((image, index) =>
                          image.img ? (
                            index == 0 ? (
                              <div
                                key={image.id}
                                className='carousel-item active'>
                                <img
                                  className='d-block w-100'
                                  src={image.img}
                                  alt='First slide'
                                />
                              </div>
                            ) : (
                              <div key={image.id} className='carousel-item'>
                                <img
                                  className='d-block w-100'
                                  src={image.img}
                                  alt='First slide'
                                />
                              </div>
                            )
                          ) : null
                        )}
                      </div>
                      <a
                        className='carousel-control-prev'
                        href='#carouselExampleControls'
                        role='button'
                        data-slide='prev'>
                        <span
                          className='carousel-control-prev-icon'
                          aria-hidden='true'></span>
                        <span className='sr-only'>Previous</span>
                      </a>
                      <a
                        className='carousel-control-next'
                        href='#carouselExampleControls'
                        role='button'
                        data-slide='next'>
                        <span
                          className='carousel-control-next-icon'
                          aria-hidden='true'></span>
                        <span className='sr-only'>Next</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {this.state.project.videos.map((video) => (
                    <div className='col-12 my-3' key={video.id}>
                      <video className='fullVideo' controls>
                        <source
                          src={
                            video.video
                              ? video.vid
                              : ""
                          }
                          type='video/mp4'
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-lg-4 sidebar pl-md-4'>
                <div className='sidebar-box'>

                  {(this.state.projects.length-1 > 0) ? (<h3>أخر المشاريع</h3>) : (null)}

                  {this.state.projects.map((project) =>
                    project.id !== this.state.project.id ? (
                      <ProjectLandScape
                        title={project.title}
                        src={project.img}
                        id={project.id}
                        views={project.views}
                        date={project.created_at}
                        type='projects'
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default SingleProject;
