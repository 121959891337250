import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import Categories from "../components/public/categories";
import Hero from "../components/public/hero";
import LoadingPage from "../components/public/loadingpage";
import Product from "../components/public/product";
import Spinner from "../components/public/spinner";
import { instance } from "../modules/instance";

export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      featured_products: [],
      isLoading: true,
    };
  }
  getProducts() {
    instance
      .get("/products")
      .then((res) => {
        let products = [];
        let featured_products = [];
        res.data.forEach((product) => {
          products.push(product);
        });
        this.setState({ products, featured_products });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.getProducts();
  }
  render() {
    return this.state.isLoading ? (
      <>

        <LoadingPage />
      </>
    ) : (
      <>
        <Hero type="products" />
        <section className='ftco-section bg-light'>
          <div className='container-xl'>
            <div className='row ' dir='rtl'>
              <Categories />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Products;
/*
{this.state.products.map((product) => (
                <Product
                  src={product.img}
                  icon={product.category.img}
                  category={product.category.name}
                  id={product.id}
                  code={product.code}
                  title={product.title}
                  views={product.views}
                  date={product.created_at}
                />
              ))}
*/