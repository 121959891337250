import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import contactus from "../assets/images/contactus.jpg";

import { instance } from "../modules/instance";
import CustomAlert from "../components/public/customalert";
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      subject: "",
      isLoading: false,
      resMessage: [],
    };
  }
  render() {
    const sendContactMessage = () => {
      this.setState({ isLoading: true });
      let data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
      };
      instance
        .post("/contact-us", data)
        .then((res) => {
          if (res.data.error) {
            this.setState({ resMessage: null });
            this.setState({
              resMessage: (
                <CustomAlert type='danger' message={res.data.errors[0]} />
              ),
            });
          } else {
            this.setState({ resMessage: null });
            this.setState({ name: "", email: "", subject: "" });
            this.setState({
              resMessage: (
                <CustomAlert
                  type='success'
                  message='شكراً على تواصلك, سوف نقوم بالرد عليك بأسرع وقت ممكن.'
                />
              ),
            });
          }
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    };
    return (
      <>
        <Breadcrumb title='تواصل معنا' />
        <section className='ftco-section ftco-no-pt ftco-no-pb'>
          <div className='container text-right'>
            <div className='row no-gutters justify-content-center'>
              <div className='col-md-12'>
                <div className='wrapper'>
                  <div className='row g-0'>
                    <div className='col-lg-6' dir='rtl'>
                      <div className='contact-wrap w-100 p-md-5 p-4'>
                        <h3>أتصل بنا</h3>
                        <p className='mb-4'>
                          نحن منفتحون على أي اقتراح أو لمجرد الدردشة
                        </p>
                        <div className='row mb-4'>
                          <div className='col-md-4'>
                            <div className='dbox w-100 d-flex align-items-start'>
                              <div className='text'>
                                <p>
                                  <span>العنوان:</span>{" "}
                                  <a href='#'>حي الاندلس - طرابلس - ليبيا</a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='dbox w-100 d-flex align-items-start'>
                              <div className='text'>
                                <p>
                                  <span>البريد الإلكتروني:</span>
                                  <a
                                    href='mailto:info@raeda.ly'
                                    className='mx-1'>
                                    info@raeda.ly
                                  </a>
                                  <br />
                                  <a
                                    href='mailto:sales@raeda.ly'
                                    className='mx-1'>
                                    sales@raeda.ly
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='dbox w-100 d-flex align-items-start'>
                              <div className='text'>
                                <p>
                                  <span>الهواتف:</span>{" "}
                                  <a
                                    href='tel:	+218 91 211 13 58'
                                    className='mx-1'
                                    dir='ltr'>
                                    +218 91 211 13 58
                                  </a>
                                  <br />
                                  <a
                                    href='tel:	+218 21 722 96 41'
                                    className='mx-1'
                                    dir='ltr'>
                                    +218 21 722 96 41
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={this.handleSubmit}
                          id='contactForm'
                          name='contactForm'
                          className='contactForm'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <input
                                  onChange={(event) => {
                                    this.setState({ name: event.target.value });
                                  }}
                                  value={this.state.name}
                                  type='text'
                                  className='form-control'
                                  name='name'
                                  id='name'
                                  placeholder='الأسم'
                                />
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <input
                                  type='email'
                                  className='form-control'
                                  name='email'
                                  id='email'
                                  onChange={(event) => {
                                    this.setState({
                                      email: event.target.value,
                                    });
                                  }}
                                  value={this.state.email}
                                  placeholder='البريد الإلكتروني'
                                />
                              </div>
                            </div>

                            <div className='col-md-12'>
                              <div className='form-group'>
                                <textarea
                                  name='message'
                                  className='form-control'
                                  id='message'
                                  cols='30'
                                  rows='4'
                                  onChange={(event) => {
                                    this.setState({
                                      subject: event.target.value,
                                    });
                                  }}
                                  value={this.state.subject}
                                  placeholder='الموضوع'></textarea>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <button
                                  type='button'
                                  className='btn btn-primary mt-3 mb-3'
                                  onClick={sendContactMessage}
                                  disabled={
                                    this.state.name === "" ||
                                      this.state.email === "" ||
                                      this.state.subject === ""
                                      ? true
                                      : false
                                  }>
                                  إرسال
                                </button>
                              </div>
                              <div className='form-group'>
                                {this.state.isLoading ? (
                                  <>
                                    <div
                                      className='spinner-grow text-warning'
                                      role='status'>
                                      <span className='sr-only'>
                                        Loading...
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {this.state.resMessage}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className='col-lg-6 d-flex align-items-stretch'>
                      <div
                        className='img w-100'
                        style={{ backgroundImage: `url(${contactus})` }}></div>
                    </div>
                    <div className='col-lg-12 d-flex align-items-stretch my-5'>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3351.1293689670465!2d13.112655315184382!3d32.868296380944635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a8ed2cbf03ce3b%3A0x2125852d57440bdc!2z2LTYsdmD2Kkg2KfZhNin2KrYrdin2K8g2KfZhNix2KfYptiv2Kkg2YTZhNin2KvYp9iqINin2YTZhdmD2KrYqNmK!5e0!3m2!1sen!2sly!4v1630504435247!5m2!1sen!2sly" width="100%" height="250"  allowfullscreen="" loading="lazy"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Contact;
