import React from "react";
import { Link } from "react-router-dom";
import { getDate } from "../../modules/functions";

const ProjectLandScape = (props) => {
  return (
    <>
      <div className='block-21 mb-4 d-flex'>
        <Link to={`/${props.type}/${props.id}/show`}>
          <a
            className='blog-img mr-4'
            style={{
              backgroundImage: `url(${props.src})`,
            }}></a>
        </Link>
        <div className='text mr-3'>
          <h3 className='heading'>
            <Link to={`/projects/${props.id}/show`}>
              <a href=''>{props.title}</a>
            </Link>
          </h3>
          <div className='meta'>
            <div>
              <a href=''>
                <span className='fa fa-eye'></span> {props.views}
              </a>
            </div>
            <div>
              <a href=''>
                <span className='fa fa-calendar'></span> {getDate(props.date)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectLandScape;
