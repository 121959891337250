import React, { Component } from "react";
import Hero from "../components/public/hero";
import { instance } from "../modules/instance";
import AppMenu from "../components/public/appmenu";
import LoadingPage from "../components/public/loadingpage";
import footerBackground from "../assets/images/icons-bg32.jpg";
import Clients from "../components/public/clients";
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      products: [],
      isLoading: true,
    };
  }
  getProjects() {
    instance
      .get("/projects?take=6")
      .then((res) => {
        this.setState({ projects: res.data });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  getProducts() {
    instance
      .get("/products?take=6")
      .then((res) => {
        let products = [];
        let featured_products = [];
        res.data.forEach((product) => {
          products.push(product);
        });
        this.setState({ products, featured_products });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  setVisitor() {
    instance.post("/set-visitor");
  }
  componentDidMount() {
    this.setVisitor();
    this.getProjects();
    this.getProducts();
  }
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Hero type='home' />
            <div >
              <AppMenu />
            </div>
            <div >
              <Clients />
            </div>

          </>
        )}
      </>
    );
  }
}

export default Home;
