import React, { Component } from "react";
import { instance } from "../../modules/instance";
import LoadingPage from "./loadingpage";
import Spinner from "./spinner";

export class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isLoading: true,
      imgIndex: 0,
    };
    this.setBgImage = this.setBgImage.bind(this);
  }
  getImages() {
    this.setState({ images: [], isLoading: true, imgIndex: 0 });
    instance
      .get(`/images/${this.props.type}/show`)
      .then((res) => {
        this.setState({ images: res.data });
      })
      .then(() => {
        this.setBgImage(this.state.images, 0);
        
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }
  componentDidMount() {
    this.getImages();
  }

  setBgImage(images, n) {
    this.setState({ n });
    if (n < images.length) {
      this.setState({ imgIndex: n });
      n++;
      setTimeout(() => {
        if (n >= images.length) {
          n = 0;
        }
        this.setBgImage(images, n);
      }, 2000);
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
           <LoadingPage />
        ) : (
          <section
            className='hero-wrap'
            className={`hero-wrap ${(this.props.type == 'home') ? 'home-hero' : null }`}
            style={{
              backgroundImage: `url(${
                this.state.images[this.state.imgIndex] &&
                this.state.images[this.state.imgIndex].image
                  ? this.state.images[this.state.imgIndex].image
                  : ""
              })`,
            }}>
            <div className='overlay'></div>
            <div className='container'>
              <div className='row no-gutters slider-text align-items-center justify-content-center'>
                <div className='col-lg-8 text-center pb-5'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-9'>
                      <h1 className=''>
                        {this.state.images[this.state.imgIndex] &&
                        this.state.images[this.state.imgIndex].title
                          ? this.state.images[this.state.imgIndex].title
                          : ""}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default Hero;
