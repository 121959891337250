import React, { Component } from "react";
import { instance } from "../modules/instance";
import Breadcrumb from "../components/public/breadcrumb";
import LoadingPage from "../components/public/loadingpage";
import ProjectItem from "../components/public/project";
import Spinner from "../components/public/spinner";

export class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      isLoading: true,
    };
  }
  getProjects() {
    instance
      .get("/projects")
      .then((res) => {
        this.setState({ projects: res.data });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  componentDidMount() {
    this.getProjects();
  }
  render() {
    return this.state.isLoading ? (
      <>
        <LoadingPage />
      </>
    ) : (
      <>
        <Breadcrumb title='المشاريع' />
        <section className='ftco-section bg-light'>
          <div className='container-xl'>
            <div className='row ' dir='rtl'>
              {this.state.projects.map((project) => (
                <ProjectItem
                  views={project.views}
                  src={project.img}
                  date={project.created_at}
                  title={project.title}
                  desc={project.desc}
                  id={project.id}
                />
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Projects;
