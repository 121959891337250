import React, { Component } from "react";
import { Link } from "react-router-dom";
import AboutUsIcon from '../../assets/images/appmenuicons/aboutus.svg'
import ProductsIcon from '../../assets/images/appmenuicons/products.svg'
import ProjectsIcon from '../../assets/images/appmenuicons/projects.svg'
import ContactUsIcon from '../../assets/images/appmenuicons/contactus.svg'
import ShowRoomIcon from '../../assets/images/appmenuicons/show-room.svg'
export class AppMenu extends Component {
render() {
return (
<div className='container testimonial-group p-5' dir="rtl">
  <div className='row text-center '>
    <div className='col-6 col-lg-2 col-md-4 col-xs-6'>
      <Link to="/about-us">
      <div className='card'>
        <div className='card-body text-center'>
          <img className="img-fluid" width="100" src={AboutUsIcon} />
          <h5><strong>عن الشركة</strong></h5>
        </div>
      </div>
      </Link>
    </div>
    <div className='col-6 col-lg-2 col-md-4 col-xs-6'>
      <Link to="/products">
      <div className='card'>
        <div className='card-body text-center'>
          <img className="img-fluid" width="100" src={ProductsIcon} />
          <h5><strong>منتجاتنا</strong></h5>
        </div>
      </div>
      </Link>
    </div>
    <div className='col-6 col-lg-3 col-md-4 col-xs-6 justify-content-center mr-5'>
      <Link to="/show-rooms">
      <div className='card circle'>
        <div className='card-body text-center'>
          <img className="img-fluid img-show-room"  width="100" src={ShowRoomIcon} />
        </div>
      </div>
      </Link>
    </div>
    <div className='col-6 col-lg-2 col-md-4 col-xs-6'>
      <Link to="/projects">
      <div className='card'>
        <div className='card-body text-center'>
          <img className="img-fluid" width="100" src={ProjectsIcon} />
          <h5><strong>المشاريع</strong></h5>
        </div>
      </div>
      </Link>
    </div>
    <div className='col-6 col-lg-2 col-md-4 col-xs-6'>
      <Link to="/contact-us">
      <div className='card'>
        <div className='card-body text-center'>
          <img className="img-fluid" width="100" src={ContactUsIcon} />
          <h5><strong>تواصل معنا</strong></h5>
        </div>
      </div>
      </Link>
    </div>
  </div>
</div>
);
}
}

export default AppMenu;