import React from "react";
import { Link } from "react-router-dom";
import {getDate} from '../../modules/functions';
export default function ProjectItem(props) {
  return (
    <div className='col-md-4 d-flex text-right'>
      <div className='blog-entry justify-content-end aos-init aos-animate'>
        <Link to={`/projects/${props.id}/show`} className="project-img">
        <a
          className='block-20 img d-flex align-items-end '
          style={{
            backgroundImage: `url(${props.src})`,
          }}></a>
        </Link>
        <div className='text'>
          <p className='meta'>
            <a>
              <i className='fa fa-eye mx-2'></i>
              {props.views}{" "}
            </a>
            <a >
              <i className='fa fa-calendar mx-1 mr-3'></i>
              <span>{props.date ? getDate(props.date) : ""}</span>{" "}
            </a>
          </p>
          <h3 className='heading mb-3'>
          <Link to={`/projects/${props.id}/show`}>
            <a >{props.title}</a>
            </Link>
          </h3>
          <p>{props.desc.substring(0, 60)}...</p>
        </div>
      </div>
    </div>
  );
}
