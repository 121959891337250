import React, { Component } from "react";
import Breadcrumb from "../components/public/breadcrumb";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

import Product from "../components/public/product";
import Spinner from "../components/public/spinner";
import { instance } from "../modules/instance";
import { isNotLogin } from "../modules/functions";
import LoadingPage from "../components/public/loadingpage";

export class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: true,
      maxPrice: true,
      MinPrice: true,
      selectedPrice: 0,
      currentMinPrice: 0,
      currentMaxPrice: 0,
      seletedType: 'all',
      searchQuery: '',
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  handlePriceChange(event) {
    // this.setState({value: event.target.value});
    console.log(event);
  }
  getProducts(type = 'all', query = '') {
    this.setState({ products: [] });
    instance
      .get(`/categories/${this.props.match.params.id}?type=${type}&q=${query}`)
      .then((res) => {
        let products = [];

        if (res.data.products.length <= 0) {
          // window.location.href = "/";
        }
        res.data.products.forEach((product) => {
          products.push(product);
        });
        this.setState({
          products,
          maxPrice: Number(res.data.max_product_price),
          MinPrice: Number(res.data.min_product_price),
          currentMinPrice: Number(res.data.max_product_price),
          currentMaxPrice: Number(res.data.min_product_price),
        });
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.getProducts();
  }
  onInputRangeChange(e) {
    this.setState({
      currentMinPrice: Number(e[0]),
      currentMaxPrice: Number(e[1])
    })
  }


  handleTypeChange(e) {
    this.setState({ seletedType: e.target.value });
    this.getProducts(e.target.value);
  }
  handleSearchChange(e) {
    this.setState({ searchQuery: e.target.value });
    this.getProducts('all', e.target.value);
  }
  onInputRangeAfterChange(e) {

    this.setState({ isLoading: true });
    instance
      .get(`/categories/${this.props.match.params.id}?type=${this.state.seletedType}&q=${this.state.searchQuery}&min_price=${this.state.currentMinPrice}&max_price=${this.state.currentMaxPrice}`)
      .then((res) => {
        let products = [];

        if (res.data.products.length <= 0) {
          //window.location.href = "/";
        }
        res.data.products.forEach((product) => {
          products.push(product);
        });
        this.setState({
          products,
          maxPrice: Number(res.data.max_product_price),
          MinPrice: Number(res.data.min_product_price),
        });

      })
      .catch((err) => {
        alert(err);
        window.location.href = "/";
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    return this.state.isLoading ? (
      <>
        <LoadingPage />
      </>
    ) : (
      <>
        <Breadcrumb
          title={
            this.state.products[0]
              ? this.state.products[0].category.name
              : "المنتجات"
          }
        />
        <section className='ftco-section bg-dark pt-0'>
          <div className='container-xl'>
            <div className='row ' dir='rtl'>
              <div className='col-12 text-right mr-3'>
                <h1 className='text-white my-5'>المنتجات</h1>
              </div>
              <div className='container'>
                <div className='row justify-content-start'>
                  <div className='col-lg-4 my-1'>
                    <form className='form-inline'>
                      <div className='form-group mb-2'>
                        <select className='form-control d-inline' value={this.state.seletedType} onChange={this.handleTypeChange} >
                          <option value="instock">تسليم فوري</option>
                          <option value="outofstock">طلبية</option>
                          <option value="all">الكل</option>
                        </select>
                      </div>
                    </form>
                  </div>

                  <div className='col-lg-4 my-1'>
                    <form className='form-inline'>
                      <div className='form-group mb-2'>
                        <input className='form-control d-inline' placeholder="البحث عن منتج" onChange={this.handleSearchChange} value={this.state.searchQuery} />
                      </div>
                    </form>
                  </div>
                  {
                    (isNotLogin()) ? (
                      <></>
                    ) : (
                      <div className='col-lg-4 my-1'>
                        <form className='form-inline'>
                          <div className='form-group mb-2'>
                            <label
                              htmlFor='staticEmail2'
                              className='mx-2 text-white my-2'>
                              سعر المنتج
                            </label>
                            <Range count={1} value={[parseInt(this.state.currentMinPrice), parseInt(this.state.currentMaxPrice)]} defaultValue={[parseInt(this.state.MinPrice), parseInt(this.state.maxPrice)]} min={parseInt(this.state.MinPrice)} max={parseInt(this.state.maxPrice)} onAfterChange={(e) => { this.onInputRangeAfterChange(e) }} onChange={(e) => { this.onInputRangeChange(e) }} />

                            <a className="text-white">من {this.state.currentMinPrice}{'$'} الي {this.state.currentMaxPrice}{'$'}</a>
                          </div>
                        </form>
                      </div>
                    )
                  }

                </div>
              </div>
              {this.state.products.map((product) => (
                <Product
                  key={product.id}
                  src={product.img}
                  icon={product.category.img}
                  category={product.category.name}
                  id={product.id}
                  code={product.code}
                  title={product.title}
                  product={product}
                  views={product.views}
                  date={product.created_at}
                />
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Category;
